import { defineMessages } from 'react-intl';

export default defineMessages({
  TABLE_PRODUCT_NAME: { id: 'preferences.created.table.header.product-name', defaultMessage: 'Name' },
  TABLE_KIND: { id: 'preferences.created.table.header.kind', defaultMessage: 'Kind' },
  TABLE_CREATED_DATE: { id: 'preferences.created.table.header.created-date', defaultMessage: 'Created' },
  TABLE_UPDATED_DATE: { id: 'preferences.created.table.header.updated-date', defaultMessage: 'Last Update' },
  TABLE_CREATED_BY: { id: 'preferences.created.table.header.created-by', defaultMessage: 'Created by' },
  TABLE_STATUS: { id: 'preferences.created.table.header.status', defaultMessage: 'Status' },
  TABLE_COMPLETION_RATE: { id: 'preferences.created.table.header.completion', defaultMessage: 'Completion rate' },
  TABLE_ACTIONS: { id: 'preferences.created.table.header.actions', defaultMessage: 'Actions' },
  UPDATE_PRODUCT: { id: 'preferences.created.table.update', defaultMessage: 'Update' },
  TABLE_COMPLETION_RATE_TOOLTIP: { id: 'preferences.created.table.header.completion.rate.tooltip', defaultMessage: 'To understand, {link} ?' },
  TABLE_COMPLETION_RATE_LINK: { id: 'preferences.created.table.header.completion.rate.tooltip.link', defaultMessage: 'how is the completion rate calculated' },
});
