import React, { memo } from 'react';
import { noop } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';

import cn from 'classnames';

import { notificationShape } from 'shapes/notification';
import { Icon } from 'antd';

import classNames from './notificationList.module.scss';

const renderToggleNotificationIcon = (isRead, isLoading) => {
  switch (true) {
    case isLoading:
      return <Icon type="loading" />;
    case isRead:
      return <Icon type="eye" theme="outlined" />;
    case !isRead:
    default:
      return <Icon type="eye" theme="filled" />;
  }
};

const enhancer = compose(
  withRouter,

  // Add a onToggleNotificationRead method.
  withPropsOnChange(
    // Only recreate the onToggleNotificationRead when
    // the read status or the onSetNotificationRead prop is changed.
    ['notification', 'onSetNotificationRead'],
    // Toggle automatically the read status.
    props => ({
      onToggleNotificationRead: () => props.onSetNotificationRead?.(!props.notification.read),
    }),
  ),

  // Add onClickNotification method.
  withPropsOnChange(
    ['notification', 'onSetNotificationRead', 'history'],
    props => ({
      onClickNotification: () => {
        // eslint-disable-next-line no-unused-expressions
        props.onSetNotificationRead?.(true);
        if (!props.notification?.disableLink) {
          props.history.push(props.notification?.link);
        }
      },
    }),
  ),

  memo,
);

const NotificationListItem = ({
  notification,
  onSetNotificationRead,
  onToggleNotificationRead,
  onRemoveNotification,
  onClickNotification,
  isSetNotificationReadLoading,
  isRemoveNotificationLoading,
  className,
  ...props
}) => (
  <li
    {...props}
    className={cn(
      className,
      classNames.notificationListItem,
      !notification.read && classNames.unread,
    )}
  >
    <button
      type="button"
      onClick={onClickNotification}
      className={cn('unstyled', classNames.notificationContent)}
    >
      {notification.content}
    </button>

    <div className={classNames.actionsContainer}>
      <button
        type="button"
        className={classNames.action}
        onClick={onToggleNotificationRead}
        disabled={isSetNotificationReadLoading}
      >
        {renderToggleNotificationIcon(notification.read, isSetNotificationReadLoading)}
      </button>

      <button
        type="button"
        className={classNames.action}
        onClick={onRemoveNotification}
        disabled={isRemoveNotificationLoading}
      >
        <Icon type={isRemoveNotificationLoading ? 'loading' : 'delete'} />
      </button>
    </div>
  </li>
);

NotificationListItem.displayName = 'NotificationListItem';

NotificationListItem.propTypes = {
  notification: notificationShape.isRequired,
  onClickNotification: PropTypes.func,
  onSetNotificationRead: PropTypes.func,
  onToggleNotificationRead: PropTypes.func,
  onRemoveNotification: PropTypes.func,
  isSetNotificationReadLoading: PropTypes.bool,
  isRemoveNotificationLoading: PropTypes.bool,
  className: PropTypes.string,
};

NotificationListItem.defaultProps = {
  onClickNotification: noop,
  onSetNotificationRead: noop,
  onToggleNotificationRead: noop,
  onRemoveNotification: noop,
  isSetNotificationReadLoading: false,
  isRemoveNotificationLoading: false,
  className: null,
};

export default enhancer(NotificationListItem);
