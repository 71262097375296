import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withProps } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { PRODUCT_SORTING } from 'config/constants';
import { productShape } from 'shapes/product';
import { userShape } from 'shapes/user';
import {
  selectTotalProductsToValidateCount,
  selectProductsToValidateList,
  selectUsersByExternalIds,
} from 'redux/users/selectors';
import { FormattedMessage } from 'react-intl';
import withLoadMore from 'react/generic/pagination/loadMore/withLoadMore';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import ProductsLoadMore from 'react/business/products/pagination/loadMore/LoadMore.presentation';
import ProductsCreatedTable from 'react/business/preferences/productsCreatedTable/ProductsCreatedTable';

import { getProductsToValidate } from 'redux/users/actions';
import messages from './preferences.validate.messages';
import classNames from './preferences.created.module.scss';

const PAGINATION_ITEM_NUMBER = 10;

const enhancer = compose(
  connect(state => ({
    productsToValidate: selectProductsToValidateList(state),
    totalCount: selectTotalProductsToValidateCount(state),
  }), {
    getProductsToValidate,
  }),

  lifecycle({
    /** Load my created products on mount. */
    async componentDidMount() {
      const params = {
        sort: PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: PAGINATION_ITEM_NUMBER,
      };

      this.props.getProductsToValidate(params);
    },
  }),

  // Lazy load and get authors from store.
  compose(
    connect(
      (state, props) => ({
        authors: selectUsersByExternalIds(
          state,
          props.productsToValidate?.map(p => p.author).filter(Boolean),
        ),
      }),
    ),

    withExternalUserLazyLoading({
      getIdsToLoadFromProps: props => props.productsToValidate?.map(p => p.author).filter(Boolean),
    }),
  ),

  // Load more component.
  withProps(props => ({
    onLoadPage: (page, { currentCount = 0 } = {}) => {
      const params = {
        sort: PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: currentCount + PAGINATION_ITEM_NUMBER,
      };
      props.getProductsToValidate(params);
    },
  })),

  withLoadMore({ itemsPropName: 'productsToValidate', pageSize: PAGINATION_ITEM_NUMBER }),
  ProductsLoadMore,

  memo,
);

const PreferencesValidatePage = ({
  productsToValidate,
  authors,
}) => (
  <div className={classNames.container}>
    { productsToValidate.length > 0 ? (
      <>
        <div className={classNames.title}>
          <FormattedMessage {...messages.VALIDATE_PRODUCT_TITLE} />
        </div>
        <div className={classNames.table}>
          <ProductsCreatedTable
            products={productsToValidate}
            authors={authors}
            view="validate"
          />
        </div>
      </>
    ) : (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.NO_DATA} />
      </div>
    ) }
  </div>
);

PreferencesValidatePage.displayName = 'PreferencesValidatePage';
PreferencesValidatePage.propTypes = {
  productsToValidate: PropTypes.arrayOf(productShape),
  totalCount: PropTypes.number,
  authors: PropTypes.objectOf(userShape),
};
PreferencesValidatePage.defaultProps = {
  productsToValidate: [],
  totalCount: 0,
  authors: {},
};

export default enhancer(PreferencesValidatePage);
