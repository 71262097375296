import { defineMessages } from 'react-intl';

export default defineMessages({
  FOLLOWED_PRODUCT_TITLE: { id: 'preferences.followed.title', defaultMessage: 'Here is the list of digital products you are following. You can manage notifications you want to receive.' },
  TABLE_STATUS: { id: 'preferences.followed.table.header.status', defaultMessage: 'Status' },
  TABLE_REVIEW: { id: 'preferences.followed.table.header.review', defaultMessage: 'Feedbacks' },
  TABLE_UPDATE: { id: 'preferences.followed.table.header.update', defaultMessage: 'Updates' },
  TABLE_SUGGESTIONS: { id: 'preferences.followed.table.header.suggestion', defaultMessage: 'Suggestions' },
  NO_DATA: { id: 'preferences.followed.nodata', defaultMessage: 'You are not following any digital product.' },
});
